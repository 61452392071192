import { useEffect, useState, useRef, useMemo, MouseEvent, KeyboardEvent } from 'react';
import {
  SelectOption,
  SelectItemDictionary,
  toggleOption,
  filterOption,
  countAllSelected,
  convertItemsToState,
  isItemVisible,
  getSelectedIDs,
  getOptionSelectedStates,
  toggleItemOpen,
  SelectItemProps,
} from './selectOptionLogic';

export const SEARCH_TERM_MIN_LENGTH = 3;

export type SelectState = {
  search: string;
  items: SelectItemDictionary;
};

export const useSelectComponent = ({
  componentId,
  items: itemsProp,
  selected,
  isParentOpenable = false,
  locale = 'fi',
  single = false,
  alwaysReturnChildren = false,
  onSelect,
}: {
  componentId: string;
  items: SelectItemProps[];
  selected: string[];
  isParentOpenable: boolean;
  locale: string;
  single?: boolean;
  alwaysReturnChildren?: boolean;
  onSelect: (data: { selected: string[]; count: number }) => void;
}) => {
  const [itemDictionary, _setItemDictionary] = useState<SelectItemDictionary>({});
  const [search, setSearch] = useState('');

  /**
   * Only used to check if incoming selected prop differs from internal selection,
   * is NOT used for select item "isSelected" state.
   */
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);

  // We use a ref here to allow for functions to get the latest state without a re-render
  const itemDictionaryRef = useRef<SelectItemDictionary>({});

  // Always update the ref to the latest value when rendered
  itemDictionaryRef.current = itemDictionary;

  /** Set the item dictionary value, and update the ref value immediately */
  const setItemDictionary = (dictionary: SelectItemDictionary) => {
    itemDictionaryRef.current = dictionary;
    _setItemDictionary(dictionary);
  };

  /** Set individual item states and merge into dictionary state */
  const setItems = (items: SelectOption[]) => {
    const updatedItemDictionary = { ...itemDictionaryRef.current };

    for (const item of items) {
      updatedItemDictionary[item.id] = { ...item };
    }

    setItemDictionary(updatedItemDictionary);
  };

  /** Helper to make accessing state with index easy */
  const items = useMemo(() => Object.values(itemDictionary).sort((a, b) => a.index - b.index), [itemDictionary]);

  /** Helper to allow getting the focused option easy */
  const focusedOption = items.find((item) => item.isFocused) ?? null;

  /** Helper to make checking search state easy */
  const isSearching = search.length >= SEARCH_TERM_MIN_LENGTH;

  /** Update state structure when itemsProp changes */
  useEffect(() => {
    const dictionary = convertItemsToState(itemsProp, locale);
    setItemDictionary(dictionary);
  }, [itemsProp, locale]);

  /** Manually override selected state, used on mount & clear */
  const overrideSelectedState = (selected: string[]) => {
    const itemDictionary = itemDictionaryRef.current;
    const optionState = getOptionSelectedStates(selected, itemDictionary, single);

    const updatedItems = [];
    for (const item of Object.keys(optionState)) {
      if (
        itemDictionary[item].isSelected !== optionState[item].isSelected ||
        itemDictionary[item].selectedChildCount !== optionState[item].selectedChildCount
      ) {
        updatedItems.push({ ...itemDictionary[item], ...optionState[item] });
      }
    }

    setSelectedIDs(selected);
    setItems(updatedItems);
  };

  /** Update internal selection when selected prop changes */
  const selectedHash = useMemo(() => [...selected].sort().join(','), [selected]);
  useEffect(() => {
    const currentSelectedHash = [...selectedIDs].sort().join(',');

    if (selectedHash === currentSelectedHash) return;

    overrideSelectedState(selected);
  }, [selectedHash]);

  /** Update visibility of items when searching */
  const handleSearchChange = (search: string) => {
    const itemDictionary = itemDictionaryRef.current;

    const updatedItems = [];

    if (search.length >= SEARCH_TERM_MIN_LENGTH) {
      const matchingIDs = [];
      for (const { id } of items.filter((item) => item.parents.length === 0)) {
        matchingIDs.push(...filterOption(itemDictionary[id], search, itemDictionary));
      }

      for (const option of Object.values(itemDictionary)) {
        const isMatching = matchingIDs.includes(option.id);
        const isVisible = isMatching;
        const isParent = option.children.length > 0;
        const isOpen = isParent && isMatching;

        // Update isMatching, update isOpen & remove any isFocused
        if (option.isMatching !== isMatching || option.isVisible !== isVisible || option.isOpen !== isOpen || option.isFocused) {
          updatedItems.push({ ...option, isMatching, isVisible, isOpen, isFocused: false });
        }
      }
    }

    if (isSearching && search.length < SEARCH_TERM_MIN_LENGTH) {
      for (const option of Object.values(itemDictionary)) {
        const isVisible = option.parents.length === 0;
        if (option.isOpen || !option.isMatching || option.isVisible !== isVisible) {
          updatedItems.push({ ...option, isVisible, isOpen: false, isMatching: true });
        }
      }
    }

    setSearch(search);
    setItems(updatedItems);
  };

  /** Clear search */
  const handleSearchClear = () => {
    handleSearchChange('');
  };

  /** Clear selection */
  const handleSearchClearSelection = () => {
    overrideSelectedState([]);
    onSelect({ selected: [], count: 0 });
  };

  /** Handle selecting an item, if is a parent item, all children will be toggled too */
  const handleSelect = (id: string) => (event?: any | null) => {
    const option = itemDictionaryRef.current[id];

    const { isSelected, updatedItems, updatedItemDictionary } = toggleOption(option, itemDictionaryRef.current, null, single);

    // The idea here is to improve first time UX by opening the selected group if it has never been opened before
    if (event && isSelected && option.children.length && !option.isTouched && !isSearching) {
      setItems([...updatedItems, ...toggleItemOpen(updatedItemDictionary[option.id], updatedItemDictionary)]);
    } else {
      setItems(updatedItems);
    }

    // Get entire selection and call "onSelect" (Select parent ID only if all children are selected, if not, select child IDs only)
    const selected = getSelectedIDs(updatedItemDictionary, alwaysReturnChildren);
    const count = countAllSelected(updatedItemDictionary);

    setSelectedIDs(selected);
    onSelect({ selected, count });
  };

  /** Handle opening an item */
  const handleOpen = (id: string) => (event?: MouseEvent<HTMLButtonElement> | null) => {
    event?.stopPropagation();

    const itemDictionary = itemDictionaryRef.current;

    const updatedItems = toggleItemOpen(itemDictionary[id], itemDictionary);

    setItems(updatedItems);
  };

  /** Handle keyboard focus on an item */
  const focusElement = (index: number) => {
    const id = items.find((x) => x.index === index)!.id;
    const item = itemDictionary[id];

    const list = document.getElementById(`${componentId}-list-wrapper`)!;
    const element = document.getElementById(`${componentId}-item-${item.index}`)!;
    const scroll = list.scrollTop;

    if (index > (focusedOption?.index || 0)) {
      const elementScroll = element.offsetTop + element.getBoundingClientRect().height;

      if (scroll + list.getBoundingClientRect().height < elementScroll) {
        element.scrollIntoView(false);
      }
    } else {
      const elementScroll = element.offsetTop;

      if (elementScroll < scroll) {
        element.scrollIntoView(true);
      }
    }

    const updatedItems = [];
    if (focusedOption) {
      updatedItems.push({ ...itemDictionary[focusedOption.id], isFocused: false });
    }

    updatedItems.push({ ...itemDictionary[id], isFocused: true });

    setItems(updatedItems);
  };

  /** Move keyboard focus down, takes into account the visibility of items */
  const handleFocusDown = () => {
    if ((focusedOption?.index || 0) >= items.length - 1) {
      return;
    }

    for (let i = focusedOption ? focusedOption.index + 1 : 0; i < items.length; i++) {
      const item = items.find((x) => x.index === i);
      if (!item) continue;
      if (isItemVisible(item, isSearching, isParentOpenable)) {
        focusElement(i);
        return;
      }
    }
  };

  /** Move keyboard focus up, takes into account the visibility of items */
  const handleFocusUp = () => {
    // We are already at the top, do nothing
    if (!focusedOption) return;

    const item = { ...focusedOption };

    // We are at the last element, remove focus from list
    if (focusedOption.index === 0) {
      item.isFocused = false;
      setItems([item]);
      return;
    }

    // Try to find the next visible element
    for (let i = focusedOption.index - 1; i >= 0; i--) {
      const option = items.find((x) => x.index === i);
      if (!option) continue;
      if (isItemVisible(option, isSearching, isParentOpenable)) {
        focusElement(i);
        return;
      }
    }

    // We are at the last visible element, remove focus from list
    item.isFocused = false;
    setItems([item]);
  };

  /** Keyboard navigation input */
  const handleSearchKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!focusedOption) return;
      event.preventDefault();
      handleSelect(focusedOption.id)();
      return;
    }

    const isSelectorRight = (event.target as any).selectionStart === (event.target as any).value.length;

    if (event.key === 'ArrowDown' && isSelectorRight) {
      event.preventDefault();

      handleFocusDown();

      return;
    }

    if (!focusedOption) return;

    if (event.key === 'ArrowUp') {
      event.preventDefault();

      handleFocusUp();

      return;
    }

    if (event.key === 'ArrowLeft') {
      event.preventDefault();

      if (!isSearching && focusedOption.children.length && focusedOption.isOpen) {
        handleOpen(focusedOption.id)();
        return;
      }

      if (focusedOption.parents.length) {
        for (const parent of [...focusedOption.parents].sort((a, b) => itemDictionary[b].index - itemDictionary[a].index)) {
          if (isItemVisible(itemDictionary[parent], isSearching, isParentOpenable)) {
            focusElement(itemDictionary[parent].index);
            return;
          }
        }
      }

      return;
    }

    if (event.key === 'ArrowRight') {
      event.preventDefault();

      if (!isSearching && focusedOption.children.length && !focusedOption.isOpen) {
        handleOpen(focusedOption.id)(event as any);
        return;
      }

      if (focusedOption.children.length && (focusedOption.isOpen || isSearching)) {
        for (const child of focusedOption.children) {
          if (isItemVisible(itemDictionary[child], isSearching, isParentOpenable)) {
            focusElement(itemDictionary[child].index);
            return;
          }
        }
      }

      return;
    }
  };

  /** Remove selector on search blur */
  const handleSearchBlur = () => {
    if (focusedOption) {
      const item = { ...focusedOption };
      item.isFocused = false;
      setItems([item]);
    }
  };

  /** Only count subitems, parent is only a selector really */
  const selectedCount = countAllSelected(itemDictionary);

  const searchProps = {
    componentId,
    search,
    selectedCount,
    focusedOption,
    onChange: handleSearchChange,
    onBlur: handleSearchBlur,
    onKeyUp: handleSearchKeyUp,
    onClearSelection: handleSearchClearSelection,
    onClear: handleSearchClear,
  };

  return { items, itemDictionary, searchProps, isSearching, handleSelect, handleOpen };
};
