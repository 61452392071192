import React, { FC, useCallback, MouseEvent, ChangeEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import EmoticonSadOutlineIcon from 'mdi-react/EmoticonSadOutlineIcon';
import { Color, borderRadius } from '../Theme';
import { Badge } from '../Badge';
import { SelectOption, SelectState, SelectItemDictionary, compareSelectItems, SelectItemProps } from '../../common-react/selectOptionLogic';
import { useSelectComponent } from '../../common-react/useSelectComponent';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';

export const SEARCH_TERM_MIN_LENGTH = 3;

const SearchInputContainer = styled.div`
  width: 100%;

  .MuiInputBase-root {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 6px;
    padding-right: 7px;
    height: 60px;
    border-radius: ${borderRadius}px ${borderRadius}px 0 0;
    background-color: transparent;
    z-index: 2;
  }

  fieldset {
    border-width: 2px;
    border-color: transparent;
    border-bottom-color: ${Color.background};
  }

  .search-icon {
    padding: 12px;
    width: 48px;
    height: 48px;
  }
`;

const SearchInputHint = styled.div`
  position: absolute;
  right: 28px;
  font-size: 0.8rem;
  opacity: 0.6;
`;

const EmptyIndicator = styled.div`
  user-select: none;
`;

export interface ItemComponentProps {
  componentId: string;
  id: string;
  option: SelectOption;
  childDictionary: SelectState;
  onSelect: (id: string) => (event: MouseEvent<HTMLDivElement>) => void;
  onOpen: (id: string) => (event: MouseEvent<HTMLButtonElement>) => void;
}

export interface SearchComponentProps {
  componentId: string;
  search: string;

  /** Should search display the selected count */
  showSelectedCount: boolean;
  selectedCount: number;

  /** Info about the currently selected option */
  focusedOption: SelectOption;

  /** Acessability information */
  ariaAutocomplete: string;

  onChange: (id: string) => void;
  onBlur: () => void;
  onKeyUp: (event: KeyboardEvent<HTMLInputElement>) => void;
  onClearSelection: () => void;
  onClear: () => void;
}

/** Compare select item states, can be used directly in memo */
export const isSelectItemStateEqual = (prev: ItemComponentProps, next: ItemComponentProps) => {
  if (prev.componentId !== next.componentId) return false;
  if (prev.id !== next.id) return false;

  if (!compareSelectItems(prev.option, next.option)) {
    return false;
  }

  /*
  for (const child of Object.keys(next.childDictionary)) {
    if (!prev.childDictionary[child]) return false;
    if (!compareSelectItems(prev.childDictionary[child], next.childDictionary[child])) {
      return false;
    }
  }
  */

  return true;
};

/**
 * The default select component search input, supports:
 * - Keyboard navigation
 * - Accessability compliance
 */
const SearchComponent: FC<SearchComponentProps> = ({
  componentId,
  search,
  showSelectedCount,
  selectedCount,
  focusedOption,
  ariaAutocomplete,
  onChange,
  onBlur,
  onKeyUp,
  onClearSelection,
  onClear,
}) => {
  const intl = useIntl();

  const clearTooltipMessage = intl.formatMessage({
    id: 'select.clearButton.tooltip',
    defaultMessage: 'Poista valinnat',
  });

  const clearSearchTooltipMessage = intl.formatMessage({
    id: 'select.clearSearchButton.tooltip',
    defaultMessage: 'Tyhjennä haku',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    return onChange(event.target.value);
  };

  return (
    <SearchInputContainer>
      <TextField
        id={`${componentId}-search`}
        variant="outlined"
        placeholder="Hae vaihtoehdoista…"
        autoFocus
        fullWidth
        value={search}
        onChange={handleChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        InputProps={{
          id: `${componentId}-search-input`,
          'aria-autocomplete': ariaAutocomplete as any,
          'aria-controls': `${componentId}-list`,
          'aria-activedescendant': focusedOption ? `${componentId}-item-${focusedOption.index}` : '',

          startAdornment: (
            <InputAdornment position="start">
              {!!search ? (
                <Tooltip title={clearSearchTooltipMessage} placement="right">
                  <IconButton aria-label={clearSearchTooltipMessage.toLowerCase()} onClick={onClear}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <MagnifyIcon className="search-icon" />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {!!search && search.length < SEARCH_TERM_MIN_LENGTH && (
                <SearchInputHint>
                  {'Kirjoita vielä [x] kirjain(ta)'.replace('[x]', `${SEARCH_TERM_MIN_LENGTH - search.length}`)}
                </SearchInputHint>
              )}
              {!!selectedCount && !showSelectedCount && <Badge>{selectedCount}</Badge>}
              {!!selectedCount && (
                <Tooltip title={clearTooltipMessage} placement="left">
                  <IconButton aria-label={clearTooltipMessage.toLowerCase()} onClick={onClearSelection}>
                    <CloseCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
    </SearchInputContainer>
  );
};

export interface ListComponentProps {
  id: string;
}

/** Basic props for select components */
export interface SelectProps {
  id: string;
  items: SelectItemProps[];
  selected: string[];
  showCount?: boolean;
  alwaysReturnChildren?: boolean;

  /** Handle item selection */
  onSelect: (data: { selected: string[]; count: number }) => void;
}

/** Internal props to be implemented by all select components */
export interface SelectBaseProps extends SelectProps {
  ariaAutocomplete?: string;

  /** How the display the list */
  containerComponent: React.ReactElement;

  /** How to wrap the items into a list */
  listComponent: React.ReactElement<ListComponentProps>;

  /** How to display items */
  itemComponent: React.ComponentType<ItemComponentProps>;

  /** How to handle search */
  showSearch?: boolean;
  searchComponent?: React.ReactElement<SearchComponentProps> | null;

  /** What to show if list is empty */
  showEmpty?: boolean;
  emptyComponent?: JSX.Element | null;

  /** Can options be hidden under parent options? */
  isParentOpenable: boolean;

  /** Completely customize how the items are rendered */
  renderListItems?: (
    items: SelectOption[],
    itemDictionary: SelectItemDictionary,
    handleSelect: (id: string) => (event?: any) => void,
    handleOpen: (id: string) => (event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void
  ) => JSX.Element[];

  className?: string;
}

export const SelectBase: FC<SelectBaseProps> = ({
  id: componentId,
  items: itemsProp,
  selected,
  onSelect,
  showCount = true,
  alwaysReturnChildren = false,
  ariaAutocomplete = 'list',

  containerComponent,
  listComponent,
  itemComponent: ItemComponent,

  showSearch = false,
  searchComponent,

  showEmpty = false,
  emptyComponent,

  isParentOpenable = false,

  renderListItems = null,

  className = '',
  children,
}) => {
  const { locale } = useRouter();
  const { items, itemDictionary, searchProps, isSearching, handleSelect, handleOpen } = useSelectComponent({
    componentId,
    items: itemsProp,
    selected,
    alwaysReturnChildren,
    isParentOpenable,
    locale,
    onSelect,
  });

  /** Render a list item */
  const renderListItem = useCallback(
    (option: SelectOption) => {
      const { id, index } = option;

      const itemProps: ItemComponentProps = {
        componentId,
        id,
        option,
        childDictionary: option.children.reduce((dictionary, id) => ({ ...dictionary, [id]: itemDictionary[id] }), {}),

        onSelect: handleSelect,
        onOpen: handleOpen,
      };

      return <ItemComponent {...itemProps} key={`${componentId}-option-${index}`} />;
    },
    [isSearching, itemDictionary]
  );

  /** Render all list items */
  const listItems = renderListItems ? renderListItems(items, itemDictionary, handleSelect, handleOpen) : items.map(renderListItem);

  /** Show a frown face is list is empty :( */
  const isListEmpty = !listItems.length;

  const allSearchProps = {
    ...searchProps,
    showSelectedCount: showCount,
    ariaAutocomplete,
  };

  return (
    <containerComponent.type {...containerComponent.props} id={componentId} className={className}>
      {/** Render search if required */}
      {showSearch &&
        (searchComponent ? <searchComponent.type {...searchComponent.props} {...searchProps} /> : <SearchComponent {...allSearchProps} />)}

      <listComponent.type {...listComponent.props} id={`${componentId}-list`} className="select-list">
        {listItems}

        {/** Render empty indidcator if required */}
        {isListEmpty &&
          showEmpty &&
          (emptyComponent || (
            <EmptyIndicator>
              <ListItem>
                <ListItemIcon>
                  <EmoticonSadOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={`Ei tuloksia haulle`} />
              </ListItem>
            </EmptyIndicator>
          ))}
      </listComponent.type>

      {children}
    </containerComponent.type>
  );
};
