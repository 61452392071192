import { FC, memo } from 'react';
import styled from 'styled-components';
import { darken, transparentize } from 'polished';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CheckboxMarkedIcon from 'mdi-react/CheckboxMarkedIcon';
import CheckboxBlankOutlineIcon from 'mdi-react/CheckboxBlankOutlineIcon';
import MinusBoxIcon from 'mdi-react/MinusBoxIcon';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import MenuUpIcon from 'mdi-react/MenuUpIcon';
import { Color, borderRadius } from '../Theme';
import { Badge } from '../Badge';
import { SelectBase, SelectProps, ItemComponentProps, ListComponentProps, isSelectItemStateEqual } from './SelectBase';

const Container = styled.div<{ maxHeight: boolean; transparent: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.maxHeight ? '100vh' : 'auto')};
  border-radius: ${(props) => (props.transparent ? 0 : borderRadius)}px;
  overflow: hidden;

  fieldset {
    border-width: 2px;
    border-color: ${(props) => (props.transparent ? 'transparent' : Color.background)};
    border-bottom-color: ${(props) => (props.transparent ? Color.background : Color.background)};
  }
`;

const ListWrapper = styled.div<{ transparent: boolean }>`
  position: relative;
  flex-shrink: 10;
  background-color: ${(props) => (props.transparent ? 'transparent' : Color.background)};
  border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
  overflow: auto;

  .MuiList-root {
    padding: 0;
  }

  .MuiCollapse-container {
    width: 100%;
  }

  .MuiCollapse-wrapperInner {
    .MuiList-root {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .MuiListItem-root {
    position: relative;
    margin-top: -2px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;

    box-sizing: border-box;

    &:not(:last-of-type) {
      border-bottom-color: ${(props) => (props.transparent ? Color.background : Color.white)};
    }

    &:not(:first-of-type) {
      border-top-color: ${(props) => (props.transparent ? Color.background : Color.white)};
    }

    &.inner {
      border-left: 2px solid ${(props) => (props.transparent ? Color.background : Color.white)};
      border-bottom-color: ${(props) => (props.transparent ? Color.background : Color.white)};
    }

    &.expanding {
      /** This element should ideally "stick" to the top if the section is expanded... */
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      .MuiIconButton-root {
        position: static;
        background-color: ${transparentize(0.85, Color.primary)};
        border: 2px solid transparent;

        box-sizing: border-box;

        transition: border 30ms ease;

        &.Mui-focusVisible {
          border-color: ${Color.primary};
        }

        &:hover {
          background-color: ${transparentize(0.7, Color.primary)};
        }

        /** Make the button easier to click on mobile to avoid accidental selects */
        &:after {
          content: '';
          position: absolute;
          display: block;
          height: 100%;
          width: 28%;
          top: 0;
          right: 0;
        }
      }
    }

    &:hover {
      background-color: ${Color.hover};

      &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        width: calc(100% - 4px);
        height: 100%;
        border: 2px solid ${Color.secondary};
        border-radius: inherit;
        pointer-events: none;
        z-index: 3;
      }

      &.inner::after {
        left: -2px;
        width: calc(100% - 2px);
      }
    }

    &.focus {
      background-color: ${Color.select};

      &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        width: calc(100% - 4px);
        height: 100%;
        border: 2px solid ${Color.primary};
        border-radius: inherit;
        pointer-events: none;
        z-index: 3;
      }

      &.inner::after {
        left: -2px;
        width: calc(100% - 2px);
      }
    }

    .badge {
      position: absolute;
      right: 66px;
    }

    &.Mui-selected {
      background-color: ${Color.primary};
      border-color: ${Color.white};
      border-top-color: ${Color.white};
      border-bottom-color: ${Color.white};
      color: ${Color.white};

      &.focus {
        background-color: ${darken(0.07, Color.primary)};

        &::after {
          border-color: ${darken(0.3, Color.primary)};
        }
      }

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: -2px;
        width: 2px;
        height: 100%;
        background-color: ${Color.primary};
      }

      &:hover,
      &.Mui-focusVisible {
        background-color: ${darken(0.07, Color.primary)};
      }

      .MuiIconButton-root {
        background-color: ${transparentize(0.8, Color.white)};

        &:hover {
          background-color: ${transparentize(0.6, Color.white)};
        }

        &.Mui-focusVisible {
          border-color: ${Color.white};
        }
      }

      .badge {
        background-color: ${Color.white};
        font-weight: 500;
        color: ${Color.primary};

        svg {
          color: ${Color.primary};
        }
      }

      svg {
        color: ${Color.white};
      }
    }
  }
`;

const ItemComponent = memo<ItemComponentProps>(({ componentId, id, option, onSelect, onOpen }) => {
  const { name, index, level, isSelected, selectedChildCount, isFocused, isMatching } = option;
  const isChild = level > 0;
  const isParent = option.children.length > 0;

  // const isSearching = search.length >= SEARCH_TERM_MIN_LENGTH;
  const isOpen = option.isOpen; // || isSearching;
  const isVisible = option.isVisible; // || isSearching;

  /** Do not render anything if this item is hidden by collapse */
  if (!isVisible) return null;

  /** Do not render anything if this item is hidden by search */
  if (/*isSearching && */ !isMatching) return null;

  const icon = isSelected ? <CheckboxMarkedIcon /> : selectedChildCount ? <MinusBoxIcon /> : <CheckboxBlankOutlineIcon />;

  const style = {
    ...(isChild ? { marginLeft: `${level}rem`, width: `calc(100% - ${level}rem)` } : {}),
  };

  const className = [isParent ? 'expanding' : '', isChild ? 'inner' : '', isFocused ? 'focus' : ''].join(' ');

  const parentProps = isParent
    ? {
        'aria-expanded': isOpen,
      }
    : {};

  return (
    <ListItem
      button
      id={`${componentId}-item-${index}`}
      className={className}
      role="treeitem"
      aria-selected={isSelected}
      tabIndex="-1"
      selected={isSelected}
      style={style}
      onClick={onSelect(id)}
      {...parentProps}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={name} />

      {!!selectedChildCount && <Badge>{selectedChildCount}</Badge>}

      {isParent && (
        <IconButton aria-label="avaa alavalikko" color="primary" tabIndex="-1" disableRipple onClick={onOpen(id)} style={{ padding: 6 }}>
          {isOpen ? <MenuUpIcon size={32} /> : <MenuDownIcon size={32} />}
        </IconButton>
      )}
    </ListItem>
  );
}, isSelectItemStateEqual);

const ListComponent: FC<ListComponentProps & { transparent: boolean }> = ({ id, transparent, children }) => {
  return (
    <ListWrapper id={`${id}-wrapper`} transparent={transparent}>
      <List id={id} role="tree" aria-multiselectable>
        {children}
      </List>
    </ListWrapper>
  );
};

export interface ListSelectProps extends SelectProps {
  maxHeight?: boolean;
  transparent?: boolean;
}

export const ListSelect: FC<ListSelectProps> = ({ maxHeight, transparent, ...props }) => {
  return (
    <SelectBase
      {...props}
      containerComponent={<Container maxHeight={maxHeight} transparent={transparent} />}
      itemComponent={ItemComponent}
      listComponent={<ListComponent id="" transparent={transparent} />}
      isParentOpenable={true}
      showSearch
      showEmpty
    />
  );
};
