import React, { FC, ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import DiamondIcon from 'mdi-react/DiamondIcon';
import { Color } from './Theme';

const PremiumIconStyle = styled.div<{ size: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  pointer-events: all;
  cursor: help;
`;

export const PremiumIcon: FC<Omit<ComponentProps<typeof DiamondIcon>, 'size'> & { size?: number }> = ({ size = 12, ...props }) => (
  <Tooltip placement="top" title={<FormattedMessage id="premiumTooltip" defaultMessage="Saatavilla Constle Pro -jäsenille" />} arrow>
    <PremiumIconStyle className="premium-icon" size={size}>
      <DiamondIcon size={size} color={Color.primary} {...props} />
    </PremiumIconStyle>
  </Tooltip>
);
